// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import model_1 from '/TT Nectar AI(科传花蜜AI智能体)/web/src/pages/add-knowledge/components/knowledge-file/model';
import model_2 from '/TT Nectar AI(科传花蜜AI智能体)/web/src/pages/chat/model';

export const models = {
model_1: { namespace: 'add-knowledge.components.knowledge-file.model', model: model_1 },
model_2: { namespace: 'chat.model', model: model_2 },
} as const
